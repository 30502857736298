import Details from './Details.js';
import { useApi } from '../../AuthProvider.js';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Audiences from './Audiences.js';
import Questions from './Questions.js';
import CustomTooltip from '../ui/CustomTooltip';

import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { styled } from '@mui/material/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from '../ui/Loading.js';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import { Step, StepLabel, Stepper } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import logoWhiteSmall from '../../assets/Logo_White_small.svg';


const steps = ['Audience and Segments', 'Questions', 'Review and Run'];

const SurveyManager = ({
    existingSurveyId,
    currentContext,
    audienceCharLimit,
    audienceCountLimit,
    segmentCharLimit,
    segmentCountLimit,
    questionCharLimit,
    questionCountLimit,
    optionCharLimit,
    optionCountLimit,
    isVideoModalOpen,
    setIsVideoModalOpen,
  }) => {
  const [errorTitle, setErrorTitle] = useState('');
  const [errorText, setErrorText] = useState('');
  const [loadedExistingSurvey, setLoadedExistingSurvey] = useState(false);
  const [persistedSurveyId, setPersistedSurveyId] = useState(null);
  const [surveyData, setSurveyData] = useState({});
  const api = useApi();
  const navigate = useNavigate();
  let surveyId = null;
  let surveyRunId = null;
  let audienceId = null;
  const [isSaving, setIsSaving] = useState(false);
  const [saveSurveySnackbarOpen, setSaveSurveySnackbarOpen] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreatingSurvey, setIsCreatingSurvey] = useState(currentContext === 'create' ? true : false);
  const [isEditingSurvey, setIsEditingSurvey] = useState(currentContext === 'edit' ? true : false);
  const [isCopyingSurvey, setIsCopyingSurvey] = useState(currentContext === 'copy' ? true : false);

  // skip to any step that the URL tells you to
  // TODO make this redundant with routes and views, QAD version for now
  const display = searchParams.get('display');
  useEffect(() => {
    if (display === 'audiences') {
      setActiveStep(0);
    } else if (display === 'questions') {
      setActiveStep(1);
    } else if (display === 'preview') {
      setActiveStep(2);
    }
  }, [searchParams]);

  //step 0 related changes
  const [surveyName, setSurveyName] = useState('');
  const [hasSurveyNameError, setHasSurveyNameError] = useState(false);
  const [isShowAudienceError, setIsShowAudienceError] = useState(false);
  const [isShowAudienceSizeError, setIsShowAudienceSizeError] = useState(false);
  const [audienceData, setAudienceData] = useState([
    {
      id: 1,
      survey_id: "",
      audience: "",
      audience_chips: [],
      audience_size: null,
      audience_id: ""
    }
  ]);
  const [existingAudiences, setExistingAudiences] = useState([]);// used to compare for edited state


  const validateSurveyName = (input) => {
    if (input.trim() === '') {
      setSurveyName(input);
      setHasSurveyNameError(true);
      setPageHasError(true);
    } else {
      setHasSurveyNameError(false);
      hasSurveyNameError === false && hasAudienceData ? setPageHasError(false) : setPageHasError(true);
      setSurveyName(input);
    }
  }
  const hasNameData = surveyName.trim() !== '';
  const hasAudienceData = audienceData[0].audience_chips.length > 0 && audienceData[0].audience_size !== '';
  const validateAudience = (audienceChips) => {
    if (audienceChips.length === 0 || audienceChips.join('').trim() === '') {
      setAudienceData([{ ...audienceData[0], audience_chips: audienceChips }]);
      setIsShowAudienceError(true);
      setPageHasError(true);
    } else {
      setIsShowAudienceError(false);
      hasSurveyNameError === false && hasAudienceData ? setPageHasError(false) : setPageHasError(true);
      setAudienceData([{ ...audienceData[0], audience_chips: audienceChips }]);
    }
  }
 const validateAudienceSize = (audienceSize) => {
    if (hasCustomSplit) {
      setIsShowAudienceSizeError(false);
      hasSurveyNameError === false && hasAudienceData ? setPageHasError(false) : setPageHasError(true);
    } else if (audienceSize !== undefined && (audienceSize === '' || parseInt(audienceSize) <= 0 || parseInt(audienceSize) > 1000)) {
      setIsShowAudienceSizeError(true);
      setPageHasError(true);
      setAudienceData([{ ...audienceData[0], audience_size: parseInt(audienceSize) }]);
    } else if (audienceSize !== undefined) {
      setIsShowAudienceSizeError(false);
      hasSurveyNameError === false && hasAudienceData ? setPageHasError(false) : setPageHasError(true);
      setAudienceData([{ ...audienceData[0], audience_size: parseInt(audienceSize) }]);
    } else if (audienceSize === undefined || audienceSize === '' || parseInt(audienceSize) <= 0 || parseInt(audienceSize) > 1000) {
      setIsShowAudienceSizeError(true);
      setPageHasError(true);
    }
  };
  const [existingSegments, setExistingSegments] = useState([]);// stores segments on load to detect add/remove later
  const [existingQuestions, setExistingQuestions] = useState([]);// stores questions on load to detect add/remove later
  const [segmentData, setSegmentData] = useState([]);
  const hasSegmentData = segmentData.some(seg => seg.segment_chips.length > 0 && seg.segment_chips.join('').trim() !== '' && seg.segment_size !== '');
  const segmentSizeLimit = 1000;
  const [hasCustomSplit, setHasCustomSplit] = React.useState(false);

  // segment callback that is passed to dynamic cards
  const segmentDeleteCallback = async (segmentId) => {
    if (segmentId === '') {
      // segment is unsaved, so just delete it from the UI
      setExistingSegments(existingSegments.filter(seg => seg.id !== segmentId));
      return true;
    } else {
      try {
        const response = segmentId !== '' ? await api.delete(`/audiences/segments/${segmentId}`) : true;
        if (response && response.status === 200) {
          setExistingSegments(existingSegments.filter(segment => segment.audience_segment_id !== segmentId));
          return true;// this is what triggers the deletion of the card in dynamicCardList
        }
      } catch (error) {
        console.error('Error making POST request', error);
        process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error deleting segment`);
        setErrorTitle(error.message || 'Error deleting segment');
        setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error deleting segment');
        return false;
      }
    }
  };

  // question callback that is passed to dynamic cards
  const questionDeleteCallback = async (questionId) => {
    if (questionId === '') {
      // question is unsaved, so just delete it from the UI
      setExistingQuestions(existingQuestions.filter(ques => ques.id !== questionId));
      return true;
    } else {
      try {
        const response = questionId !== '' ? await api.delete(`/questions/${questionId}`) : true;
        if (response && response.status === 200) {
          setExistingQuestions(existingQuestions.filter(ques => ques.question_id !== questionId));
          return true;// this is what triggers the deletion of the card in dynamicCardList
        }
      } catch (error) {
        console.error('Error making POST request', error);
        process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error deleting question`);
        setErrorTitle(error.message || 'Error deleting question');
        setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error deleting question');
        return false;
      }
    }


  };

  //step 0 end

  //step 1 related changes
  const [questionsData, setQuestionsData] = useState([]);


  const hasQuestionsData = questionsData.some(textField => textField.question_text !== '' && textField.question_type !== '');
  const [audienceSuggestions, setAudienceSuggestions] = useState([]);
  const [segmentSuggestions, setSegmentSuggestions] = useState([]);
  let segmentSuggestionsFetched = false;
  let audienceSuggestionsFetched = false;
  // on load, grab suggestions for segments + audience
  useEffect(() => {
    const fetchAudienceSuggestions = async () => {
      try {
        const response = await api.get('/suggest/audience-traits');
        if (response && response.status === 200) {
          setAudienceSuggestions(response.data.traits);
          audienceSuggestionsFetched = true;
        }
      } catch (error) {
        console.error('Error fetching audience suggestions:', error);
        process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error getting audience trait suggestions`);
        setErrorText('Audience trait suggestions are unavailable at this time');
      }
    }
    const fetchSegmentSuggestions = async () => {
      try {
        const response = await api.get('/suggest/segment-traits');
        if (response && response.status === 200) {
          setSegmentSuggestions(response.data.traits);
          segmentSuggestionsFetched = true;
        }
      } catch (error) {
        console.error('Error fetching segment suggestions:', error);
        process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error getting subgroup trait suggestions`);
        setErrorText('Subgroup trait suggestions are unavailable at this time');
      }
    }
    audienceSuggestionsFetched ? console.log('audience trait suggestions exist:', audienceSuggestions) : fetchAudienceSuggestions();
    segmentSuggestionsFetched ? console.log('seg trait suggestions exist:', segmentSuggestions) : fetchSegmentSuggestions();
  }, []);

  //step 1 end
  useEffect(() => {
    process.env.REACT_APP_ENV === 'staging' && console.log('name changed:', surveyName);
      setSurveyData({ ...surveyData, name: surveyName });
  }, [surveyName]);

  useEffect(() => {
      process.env.REACT_APP_ENV === 'staging' && console.log('audience changed:', audienceData);
      setSurveyData({
        ...surveyData,
        survey_id: persistedSurveyId,
        audiences:[ ...audienceData ]
      });
  }, [audienceData]);

  useEffect(() => {
    if (segmentData.length > 0 && activeStep === 0) {
      process.env.REACT_APP_ENV === 'staging' && console.log('segment changed:', segmentData);
      setSurveyData({ ...surveyData, audiences: [{
        ...surveyData.audiences[0],
        segments: segmentData
      }]});
      setAudienceData([{ ...audienceData[0], segments: segmentData }]);
      validateSegments();
    }
  }, [segmentData]);

  useEffect(() => {
    if (questionsData.length > 0 && activeStep === 1) {
      process.env.REACT_APP_ENV === 'staging' && console.log('questions changed:', questionsData);
      setSurveyData({ ...surveyData, questions: questionsData });
      validateQuestions();
    }
  }, [questionsData]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };


  const handleCreateNext = async () => {
    let newSkipped = skipped;
    var isError = false;

    // start with no errors
    setErrorTitle('');
    setErrorText('');

    // Fixed issue with calling createAudience() multiple times
    // by seperating conditional check for activeStep === 0
    // and checking if audienceData is not empty
    if (activeStep === 0) {
      // audience check null or not
      if (!hasAudienceData || hasSurveyNameError || surveyName.trim() === '') {
        isError = true;
      }
      !hasAudienceData || isShowAudienceError ? setIsShowAudienceError(true) : setIsShowAudienceError(false);
      hasSurveyNameError || surveyName.trim() === '' ? setHasSurveyNameError(true) : setHasSurveyNameError(false);
    // segment check if null or not
      const segmentsWithBlankTraits = segmentData.some(seg => seg.segment_chips.join('').trim() === '');
      const segmentsWithBlankSize = segmentData.some(seg => seg.segment_size === '');

      if (segmentData.length > 0 && (segmentsWithBlankSize || segmentsWithBlankTraits)) {
        setSegmentData(segmentData.map(seg => {
          return {
            ...seg,
            has_name_error: seg.segment_chips.join('').trim() === '',
            has_size_error: seg.segment_size === ''
          }
        }));
        return;
      }
    }

    // question check if null or not
    if (activeStep === 1) {
      const questionsWithNoQuestion = questionsData.some(ques => ques.question_text.trim() === '');

      if (questionsWithNoQuestion) {
        setQuestionsData(questionsData.map(ques => {
          return {
            ...ques,
            has_question_error: ques.question_text.trim() === ''
          }
        }));
        return;
      }
    }

    // if nt error it will redirect to next page
    if (!isError) {
      if (activeStep === 0) {
        // name / audience / segments
        saveSurvey();
        setActiveStep(activeStep + 1);
      } else if (activeStep === 1) {
        // questions
        saveSurvey();
        setActiveStep(activeStep + 1);
      } else if (activeStep === 2) {
        // review
        setIsSaving(false);
        setActiveStep(activeStep + 1);

        // create new survey mode, so create all the elements of the survey
        let stepSuccess = false;

        stepSuccess = await runSurvey();

        if (stepSuccess !== true) {
          setErrorTitle('Error running survey');
          process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error validating during runSurvey`);
          setErrorText('There was an unknown error during the runSurvey validation process');

          setActiveStep(2);
          return;
        }

        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        }
    }
  };

  const selectSteps = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    saveSurvey(activeStep);
  };



  const createSurvey = async (surveyName) => {
    if (persistedSurveyId !== null) {
      surveyId = persistedSurveyId;
      process.env.REACT_APP_ENV === 'staging' && console.log('persistedSurveyId:', surveyId);
    }

    if (surveyId !== null && surveyId !== '') {
      return surveyId;
    }

    try {
      const response = await api.post('/surveys/', {
        name: surveyName.substring(0, 255),
      });

      return response.data.survey_id;

    } catch (error) {
      console.error('Error making POST request', error);
      process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error creating survey`);
      setErrorTitle(error.message || 'Error creating survey');
      setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error creating survey');
    }
  };

  const createAudience = async () => {
    if (isEditingSurvey && persistedSurveyId !== null) {
      surveyId = persistedSurveyId;
    }

    if (isCreatingSurvey || isCopyingSurvey) {
      process.env.REACT_APP_ENV === 'staging' && console.log('creating a new audience...');
      try {
        surveyId = await createSurvey(surveyName);
      } catch (error) {
        console.error('Error making POST request', error);
        process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error creating survey`);
        setErrorTitle(error.message || 'Error creating survey');
        setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error creating survey');
        return false;
      } finally {
        setPersistedSurveyId(surveyId);
        setAudienceData([{ ...audienceData[0], survey_id: surveyId }]);

        const surveyResponse = await api.get(`/surveys/${surveyId}`);
        if (surveyResponse && surveyResponse.status === 200) {
          process.env.REACT_APP_ENV === 'staging' && console.log('survey created:', surveyResponse.data);
          try {
            const response = await api.post('/audiences/', {
              survey_id: surveyResponse.data.survey_id,
              audience: audienceData[0].audience,
              audience_size: audienceData[0].audience_size,
              audience_chips: audienceData[0].audience_chips,
              custom_distribution: audienceData[0].custom_distribution
            });
            if (response && response.status !== 200) {
              console.error('Error making AUDIENCE POST request', response.data);

              // console.error('Error making POST request', error);
              // setErrorTitle(error.message || 'Error creating audience');
              // setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error creating audience');
              return false;
            } else {
              audienceId = response.data.audience_id;
              setAudienceData([{ ...audienceData[0], survey_id: response.data.survey_id, audience_id: response.data.audience_id }]);
              setExistingAudiences(audienceData);
              if (segmentData.length > 0) {
                const segmentSuccess = await saveSegments(response.data.audience_id);
                if (segmentSuccess !== true) {
                  setErrorText('Error creating subgroups');
                  setActiveStep(0);
                  return;
                } else {
                  console.log('segments created');
                }
              }
            }
          } catch (error) {
            console.error('Error making POST request', error);
            process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error creating audience`);
            setErrorTitle(error.message || 'Error creating audience');
            setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error creating audience');
            return false;
          }
        }
      }
    }

    return true;
  };

  const compareAllValues = (obj1, obj2) => {
    // console.log(JSON.stringify(obj1));
    // console.log(JSON.stringify(obj2));
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const saveSegments = async (audienceId) => {
    if (segmentData.length > 0) {
      // unsaved segments have no audience_segment_id
      const newSegments = segmentData.filter(seg => seg.audience_segment_id === undefined || seg.audience_segment_id === '');
      process.env.REACT_APP_ENV === 'staging' && console.log('existingSegments:', existingSegments);
      process.env.REACT_APP_ENV === 'staging' && console.log('segmentData:', segmentData);
      process.env.REACT_APP_ENV === 'staging' && console.log('newSegments:', newSegments);
      // find any segments in existing segments that have a corresponding segment in segmentData
      // that has been edited
      let editedSegments = [];
      existingSegments.length > 0 && existingSegments.forEach(seg => {
        let segmentDataMatch = segmentData.find(s => s.id === seg.id);
        if (!compareAllValues(seg, segmentDataMatch)) {
          editedSegments.push(segmentDataMatch);
        }
      });

      process.env.REACT_APP_ENV === 'staging' && console.log('editedSegments:', editedSegments);
      if (existingSegments.length === 0 && newSegments.length === 0 && editedSegments.length === 0) {
        // shouldn't happen
        console.log('no segments to save, check segmentData');
      } else if (existingSegments.length === 0) {
        // there are no existing segments, so all segments in segmentData are new
        if (newSegments.length > 0) {
          try {
            // create new segments
            const segmentResponse = await api.post(`/audiences/segments/bulk`, {
              audience_id: audienceId,
              segments: newSegments
            });
            if (segmentResponse && segmentResponse.status === 200) {
              // for every segment in segmentData, update the corresponding survey_id
              // with the one returned from the server
              segmentData.forEach((seg, index) => {
                seg.audience_id = audienceData[0].audience_id;
                seg.audience_segment_id = segmentResponse.data[index].audience_segment_id;
              });
            }
          } catch (error) {
            console.error('Error making POST request', error);
            process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error creating segments`);
            setErrorTitle(error.message || 'Error creating subgroups');
            setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error creating subgroups');
          }
        }
      } else {
        // there are existing segments, so we need to update the existing ones and create the new ones
        if (newSegments.length > 0) {
          try {
            // create new segments
            const segmentResponse = await api.post(`/audiences/segments/bulk`, {
              audience_id: audienceId,
              segments: newSegments
            });
            if (segmentResponse && segmentResponse.status === 200) {
              // for every segment in segmentData, update the corresponding segment_id and survey_id
              // with the one returned from the server
              segmentData.forEach((seg, index) => {
                if (!seg.audience_segment_id) {
                  // find the segment in segmentResponse with the same sort_order as the one in segmentData
                  // and set audience_segment_id to the new one returned from the server
                  for (let i = 0; i < segmentResponse.data.length; i++) {
                    if (seg.sort_order === segmentResponse.data[i].sort_order) {
                      seg.audience_id = audienceData[0].audience_id;
                      seg.audience_segment_id = segmentResponse.data[i].audience_segment_id;
                    }
                  }
                }
              });
            }
          } catch (error) {
            console.error('Error making POST request', error);
            process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error creating segments`);
            setErrorTitle(error.message || 'Error creating subgroups');
            setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error creating subgroups');
          }
        }
        if (editedSegments.length > 0) {
          for (let i = 0; i < editedSegments.length; i++) {
            try {
              const segmentResponse = await api.put(`/audiences/segments/${editedSegments[i].audience_segment_id}`, {
                segment_chips: editedSegments[i].segment_chips,
                segment_size: Math.floor(parseInt(editedSegments[i].segment_size)),
                sort_order: editedSegments[i].sort_order
              });
              if (segmentResponse && segmentResponse.status === 200) {
                console.log('edited segments updated');
                const index = segmentData.findIndex(seg => seg.id === editedSegments[i].id);
                segmentData[index] = editedSegments[i];
              }
            } catch (error) {
              console.error('Error making POST request', error);
              process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error editing segments`);
              setErrorTitle(error.message || 'Error editing subgroups');
              setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error editing subgroups');
            }
          }
          // Update existingSegments after successful edits
          setExistingSegments(JSON.parse(JSON.stringify(segmentData)));
        }
      }
    }
    return true;
  }

  const saveQuestions = async (survey_id) => {
    if (questionsData.length > 0) {
      const newQuestions = questionsData.filter(ques => ques.question_id === undefined || ques.question_id === '');
      process.env.REACT_APP_ENV === 'staging' && console.log('existingQuestions:', existingQuestions);
      process.env.REACT_APP_ENV === 'staging' && console.log('questionsData:', questionsData);
      process.env.REACT_APP_ENV === 'staging' && console.log('newQuestions:', newQuestions);
      // find any questions in existing questions that have a corresponding edited question in questionsData
      let editedQuestions = [];
      existingQuestions.length > 0 && existingQuestions.forEach(ques => {
        let questionsDataMatch = questionsData.find(q => q.id === ques.id);
        if (!compareAllValues(ques, questionsDataMatch)) {
          editedQuestions.push(questionsDataMatch);
        }
      });

      process.env.REACT_APP_ENV === 'staging' && console.log('editedQuestions:', editedQuestions);
      if (existingQuestions.length === 0 && newQuestions.length === 0 && editedQuestions.length === 0) {
      } else if (existingQuestions.length === 0) {
        // there are no existing questions, so all questions in questionsData are new
        if (newQuestions.length > 0) {
          try {
            // create new questions
            const questionResponse = await api.post(`/questions/bulk`, {
              survey_id: survey_id,
              questions: newQuestions
            });
            if (questionResponse && questionResponse.status === 200) {
              setSaveSurveySnackbarOpen(true);
              // for every question in questionsData, update the corresponding question_id and survey_id
              // with the one returned from the server
              questionsData.forEach((ques, index) => {
                if (!ques.question_id) {
                  ques.question_id = questionResponse.data[index].question_id;
                  ques.survey_id = questionResponse.data[index].survey_id;
                }
              });
            }
            setExistingQuestions(questionsData);
          } catch (error) {
            console.error('Error making POST request', error);
            process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error creating questions`);
            setErrorTitle(error.message || 'Error creating questions');
            setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error creating questions');
          }
        }
      } else {
        // there are existing questions, so we need to update the existing ones and create the new ones
        if (newQuestions.length > 0) {
          try {
            // create new questions
            const questionResponse = await api.post(`/questions/bulk`, {
              survey_id: survey_id,
              questions: newQuestions
            });
            if (questionResponse && questionResponse.status === 200) {
              setSaveSurveySnackbarOpen(true);
              // for every question in questionsData, update the corresponding question_id and survey_id
              // with the one returned from the server
              questionsData.forEach((ques, index) => {
                if (!ques.question_id) {
                  // find the question in questionsData that matches the one in questionResponse
                  // based on the sort_order (b/c other fields can be duplicates) and
                  // set question_id and survey_id to the new ones returned from the server
                  for (let i = 0; i < questionResponse.data.length; i++) {
                    if (ques.sort_order === questionResponse.data[i].sort_order) {
                      ques.question_id = questionResponse.data[i].question_id;
                      ques.survey_id = questionResponse.data[i].survey_id;
                    }
                  }
                }
              });
            }
          } catch (error) {
            console.error('Error making POST request', error);
            process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error updating questions`);
            setErrorTitle(error.message || 'Error updating questions');
            setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error updating questions');
          }
        }
        if (editedQuestions.length > 0) {
          for (let i = 0; i < editedQuestions.length; i++) {
            try {
              const response = await api.put(`/questions/${editedQuestions[i].question_id}`, editedQuestions[i]);
              if (response?.status === 200) {
                setSaveSurveySnackbarOpen(true);
                console.log('saving...');
                console.log(editedQuestions[i]);
                // Update questionsData with response data instead of edited data
                const index = questionsData.findIndex(ques => ques.id === editedQuestions[i].id);
                questionsData[index] = editedQuestions[i];
              }
            } catch (error) {
              console.error('Error making POST request', error);
              process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error updating questions`);
              setErrorTitle(error.message || 'Error updating questions');
              setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error updating questions');
              return false;
            }
          }
          // Update existingQuestions with server response data
          setExistingQuestions(JSON.parse(JSON.stringify(questionsData)));
        }
      }
      setSaveSurveySnackbarOpen(true);
    }

    return true;
  }

  const runSurvey = async () => {

    if (surveyId === null) {
      surveyId = surveyData.survey_id;
    }
    try {
      const confirmSurveyResponse = await api.get(`/surveys/${surveyId}/run`);

      if (confirmSurveyResponse) {
        const jobData = confirmSurveyResponse.data;
        surveyRunId = jobData.survey_run_id;
        surveyId = null;
        setPersistedSurveyId(null);
        audienceId = null;
      }
      process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', `Run Survey`);
      navigate('/surveys');
    } catch (error) {
      console.error('Error making POST request', error);
      process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error running survey`);
      setErrorTitle(error.message || 'Error running survey');
      setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error running survey');
    }
}

  const handleRemoveQuestion = (index) => {
    setQuestionsData(questionsData.filter((_, i) => i !== index));
  }

  const handleUpload = async (event) => {
    if (event.target.files.length === 0) {
      setErrorText('Please select a file to upload');
      return;
    } else {
      setErrorText('');
      if (persistedSurveyId === null) {
        // xlsx upload endpoint needs an ID, so we need to create a survey first
        const createResponse = await createSurvey(surveyName);
        surveyId = createResponse;
        setPersistedSurveyId(surveyId);
      } else {
        surveyId = persistedSurveyId
      }

      try {
        const response = await api.post(`/surveys/${surveyId}/upload-questions/xlsx`,
          {
            'xlsx_file': event.target.files[0],
          },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        if (response && response.status === 200) {
          // now that questions have been uploaded, pull them back from the api
          process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', `Bulk Question Upload`);
          const getSurvey = await api.get(`/surveys/${surveyId}`);
          if (getSurvey && getSurvey.status === 200) {
            // questions in the DB only need options[] but in the ui we need raw_options for the table, so we create them before we set the questions data
            // and we also give each question an id, and we make sure the question type value is lowercase
            getSurvey.data.questions.forEach((question, index) => {
              question.id = index + 1;
              question.question_type = question.question_type.toLowerCase();
            });
            setQuestionsData(getSurvey.data.questions);
            setExistingQuestions(getSurvey.data.questions);
          }
        }
      } catch (error) {
        console.error('Error making POST request', error);
        process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error uploading questions from XLSX`);
        setErrorTitle(error.message || 'Error uploading questions from XLSX');
        setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error uploading questions from XLSX');
      }
    }
  }

  const ErrorAlert = ({ errorTitle, errorText }) => {
    if (!errorText || errorText === '') {
      return;
    } else {
      window.scrollTo(0, 0);
    }

    return (
      <Alert
        severity="error"
        style={{ marginBottom: "20px", borderRadius: "20px" }}
        onClose={() => { setErrorText(''); setErrorTitle(''); }}>
        <AlertTitle><strong>{ 'Error: ' + errorTitle}</strong></AlertTitle>
        We're sorry, an error has occurred while making this request. Please try again or contact Crowdwave support.<br /><br />
        Error Details: {errorText}
      </Alert>
    );
  }

  const saveSurvey = async () => {

    if (process.env.REACT_APP_ENV === 'staging') {
      console.log('saving survey', persistedSurveyId);
      console.log('surveyName:', surveyName);
      console.log('audienceData', audienceData);
      console.log('existingSegments', existingSegments);
      console.log('segmentData', segmentData);
      console.log('questionsData', questionsData);
      console.log('surveyData', surveyData);
      console.log('isEditingSurvey', isEditingSurvey);
      console.log('isCreatingSurvey', isCreatingSurvey);
      console.log('isCopyingSurvey', isCopyingSurvey);
    }

    setIsSaving(true);
    if (!hasNameData || !hasAudienceData) {
        // audience check null or not
        !hasAudienceData ? setIsShowAudienceError(true) : setIsShowAudienceError(false);
        hasSurveyNameError || surveyName.trim() === '' ? setHasSurveyNameError(true) : setHasSurveyNameError(false);
      return;
    }

    // regardless of the current step, when the user clicks save, we want to determine whether
    // or not they're editing an existing survey. If so, we update that survey and its associated entities.
    // If not, then upon each save we create new entities and associate them with the new survey. Upon first save, we use
    // audience to create a survey (and survey_id)

    if (isEditingSurvey) {
      console.log('saving while editing, existingSurvey:', existingSurveyData);
      // editing existing survey
      if (activeStep === 0) {
        // check for updates to name
        if (surveyName !== existingSurveyData.name) {
          try {
            // attempt to save name
            const nameResponse = await api.put(`/surveys/${surveyData.survey_id}`, { name: surveyData.name });
            if (nameResponse && nameResponse.status === 200) {
              process.env.REACT_APP_ENV === 'staging' && console.log('name updated in DB');
              setExistingSurveyData({ ...existingSurveyData, name: surveyData.name });
            }
          } catch (error) {
            console.error('Error making POST request', error);
            process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error creating survey name`);
            setErrorTitle(error.message || 'Error creating survey name');
            setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error creating survey name');
          }
        } else {
          process.env.REACT_APP_ENV === 'staging' && console.log('no changes detected to name');
        }
        // check for updates to audience
        if (
          audienceData[0].audience_size !== existingSurveyData.audiences[0].audience_size ||
          audienceData[0].audience !== existingSurveyData.audiences[0].audience ||
          audienceData[0].audience_chips !== existingSurveyData.audiences[0].audience_chips ||
          audienceData[0].custom_distribution !== existingSurveyData.audiences[0].custom_distribution
        ) {
          try {
            const audienceResponse = await api.put(`/audiences/${audienceData[0].audience_id}`, {
              audience: audienceData[0].audience,
              audience_size: audienceData[0].audience_size,
              audience_chips: audienceData[0].audience_chips,
              custom_distribution: audienceData[0].custom_distribution
            });
            if (audienceResponse && audienceResponse.status === 200) {
              process.env.REACT_APP_ENV === 'staging' && console.log('audience updated in DB');
              setExistingSurveyData({ ...existingSurveyData, audiences: [{ ...audienceData[0] }] });
              setSaveSurveySnackbarOpen(true);
            }
          } catch (error) {
            console.error('Error making POST request', error);
            process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error updating audience`);
            setErrorTitle(error.message || 'Error updating audience');
            setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error updating audience');
          }
        } else {
          process.env.REACT_APP_ENV === 'staging' && console.log('no changes detected to audience');
        }

        await saveSegments(audienceData[0].audience_id);
      } else {
        await saveQuestions(surveyData.survey_id);
      }

    } else if (isCreatingSurvey || isCopyingSurvey) {
      // creating / copying survey

      if (activeStep === 0) {
        // if null/'', that means it's a create / copy that hasn't saved its audience yet
        if (audienceData[0].audience_id === null || audienceData[0].audience_id === '') {
          const createSuccess = await createAudience();// this creates an audience and calls createSurvey
          if (createSuccess !== true) {
            setErrorText('Error creating audience');
            setActiveStep(0);
            return;
          } else {
            if (process.env.REACT_APP_ENV === 'staging') {
              console.log('audience created');
              console.log(persistedSurveyId);
              console.log('surveyData:', surveyData);
            }

            // as soon as we save successfully, we are now editing and no longer creating/copying
            setIsCopyingSurvey(false);
            setIsCreatingSurvey(false);
            setIsEditingSurvey(true);
            // at this point surveyData gets updated, and in useEffect we check to see if there are questions to save
            setSaveSurveySnackbarOpen(true);
            setExistingSurveyData({ ...existingSurveyData, audiences: [{ ...audienceData[0] }] });
          }
        } else {
          // there's a surveyId/persistedSurveyId, so we need to compare audience with existingAudience
          // to see if its been edited and if so, update it
          if (existingAudiences.length > 0) {
            // check if audience has been edited
            if (audienceData[0].audience !== existingAudiences[0].audience) {
              try {
                const audienceResponse = await api.put(`/audiences/${audienceData[0].audience_id}`, {
                  audience: audienceData[0].audience,
                  audience_size: audienceData[0].audience_size,
                  audience_chips: audienceData[0].audience_chips
                });
                if (audienceResponse && audienceResponse.status === 200) {
                  // as soon as we save successfully, we are now editing and no longer creating/copying
                  setIsCopyingSurvey(false);
                  setIsCreatingSurvey(false);
                  setIsEditingSurvey(true);
                  setSaveSurveySnackbarOpen(true);
                  if (surveyData.questions.length > 0) {
                    await saveQuestions(surveyData.survey_id);
                  }
                } else {
                  setErrorText('Error updating audience');
                  setActiveStep(0);
                  return;
                }
              } catch (error) {
                console.error('Error making POST request', error);
                process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error updating audience`);
                setErrorTitle(error.message || 'Error updating audience');
                setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error updating audience');
                setActiveStep(0);
                return;
              }
            }
          }
        }
      } else if (activeStep === 1) {
        if (questionsData.length > 0) {
          await saveQuestions(surveyData.survey_id);
        }
      }
    }
  setIsSaving(false);
  }

  // tracking loaded data here as a temp obj so we can update it on save, then compare to detect changes and save accordingly
  const [existingSurveyData, setExistingSurveyData] = useState({});

  const loadExistingSurvey = async () => {
    if (process.env.REACT_APP_ENV === 'staging') {
      console.log('loading existing survey');
      console.log('existingSurveyId:', existingSurveyId);
    }
    if (surveyId !== null || isCreatingSurvey) return;

    try {
    const response = await api.get(`/surveys/${existingSurveyId}`);

    if (response && response.status === 200) {
      setExistingSurveyData(response.data);
      setLoadedExistingSurvey(true);
      let existingSurvey = response.data;
      process.env.REACT_APP_ENV === 'staging' && console.log('found survey');
      process.env.REACT_APP_ENV === 'staging' && console.log('existingSurvey:', existingSurvey);

      if (isEditingSurvey) {
        // load the existing survey in its entirety
        surveyId = existingSurvey.survey_id;
        setPersistedSurveyId(surveyId);
        setSurveyName(existingSurvey.name);
        // setAudienceData([{ ...existingSurvey.audiences[0] }]);
        let tempAudienceData = [{
          ...existingSurvey.audiences[0],
          audience_chips: existingSurvey.audiences[0].audience_chips?.length > 0 ? existingSurvey.audiences[0].audience_chips : [existingSurvey.audiences[0].audience]
        }];
        setHasCustomSplit(existingSurvey.audiences[0].custom_distribution ? existingSurvey.audiences[0].custom_distribution : false);
        setAudienceData(tempAudienceData);

        // generate ids for segments and questions
        let tempSegments = existingSurvey.audiences[0].segments.map((seg, index) => {
          return {
            ...seg,
            id: Math.floor(Math.random() * 1000000),
            segment_chips: seg.segment_chips?.length > 0 ? seg.segment_chips : [seg.segment_name]
          }
        });
        let tempQuestions = existingSurvey.questions.map((ques, index) => {
          return {
            ...ques,
            id: Math.floor(Math.random() * 1000000),
            options: ques.question_type === 'numeric rating' && ques.options.length === 0 ? ["1","5"] : ques.options
          }
        });
        setSegmentData(tempSegments);
        setExistingSegments(JSON.parse(JSON.stringify(tempSegments)));
        setExistingQuestions(JSON.parse(JSON.stringify(tempQuestions)));
        setQuestionsData(tempQuestions);
        setSurveyData(existingSurvey);
        process.env.REACT_APP_ENV === 'staging' && console.log('done loading for edit, surveyData:', surveyData);
      } else {
        // shouldn't happen
        console.log('invalid survey context');
      }
    }
   } catch (error) {
      console.error('Error making POST request', error);
      process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Error', `Error loading existing survey`);
      setErrorTitle(error.message || 'Error loading existing survey');
      setErrorText(error.response.data.detail[0].msg || error.response.data.detail || 'Error loading existing survey');
      surveyId = 0;
      setPersistedSurveyId(0);
    }
  }

  ////////////////////////////////////
  // Overall validation functions
  ////////////////////////////////////
  // The UI live-tracks input to trigger error classes, but does not influence validation.
  // These functions run validation checks whenever their respective data changes. Items with errors
  // get pushed to their respective error arrays, which are then checked in the saveSurvey function
  // and are used to determine if the user can proceed to the next step + disable save / continue buttons
  const [segmentsWithErrors, setSegmentsWithErrors] = React.useState([]);
  const [questionsWithErrors, setQuestionsWithErrors] = React.useState([]);
  const [pageHasError, setPageHasError] = React.useState(false);

  const validateSegments = () => {
    let errorSegments = [];

    segmentData.forEach(seg => {
      // double check to make sure that the segment isn't the only one, and also entirely empty, which is the default create state
      if (segmentData.length !== 1 || (seg.segment_chips.join('').trim() === '' && seg.segment_size !== '')) {
        if (seg.segment_chips.join('').trim() === '' || seg.segment_size === '' || seg.segment_size <= 0 || seg.segment_size > 1000) {
          errorSegments.push(seg);
        } else {
          // if there's no error with the segment but the segment exists in segmentsWithErrors, remove it
          if (errorSegments.includes(seg)) {
            errorSegments = errorSegments.filter(segment => segment !== seg);
          }
        }
      }
    });
    setSegmentsWithErrors(errorSegments);
    errorSegments.length > 0 ? setPageHasError(true) : setPageHasError(false);
  }
  const validateQuestions = () => {
    let errorQuestions = [];
    questionsData.forEach(ques => {
      if (
        ques.question_text.trim() === '' ||
        ques.question_text.length > questionCharLimit ||
        ((ques.question_type === 'single choice' || ques.question_type === 'multiple choice') &&  ques.options.length > optionCountLimit) ||
        ((ques.question_type === 'single choice' || ques.question_type === 'multiple choice') &&  ques.options.length === 0)
      ) {
        errorQuestions.push(ques);
      } else {
        // if there's no error with the question but the question exists in questionsWithErrors, remove it
        if (errorQuestions.includes(ques)) {
          errorQuestions = errorQuestions.filter(question => question !== ques);
        }
      }
    });
    setQuestionsWithErrors(errorQuestions);
    errorQuestions.length > 0 ? setPageHasError(true) : setPageHasError(false);
  }

  const [dynamicTooltipContent, setDynamicTooltipContent] = React.useState(null);
  const parser = new DOMParser();
  const getDynamicTooltipContent = async (trigger) => {
    // the id of the trigger element should match the id of the FAQ element we want to pull in
    console.log('hovering over', trigger.id);
    setDynamicTooltipContent(<Loading />);
    try {
      const response = await fetch('https://crowdwave-ai.github.io/faq/');
      const text = await response.text();
      const doc = parser.parseFromString(text, 'text/html');
      const snippet = doc.getElementById(trigger.id);
      setDynamicTooltipContent(snippet ? snippet.innerHTML : 'Content not found');
    } catch (error) {
      console.error('Error making GET request', error);
      setErrorTitle(error.message || 'Error fetching FAQ snippet');
      setErrorText(error.response?.data?.detail[0]?.msg || error.response?.data?.detail || 'Error fetching FAQ snippet');
    }
  }

  if (existingSurveyId !== null && loadedExistingSurvey === false) {
    loadExistingSurvey();
    return <Loading />;
  }

  return (
    <div className="dashboard">
        <div className="dashboard-content">
          <Box xs={12} md={12} lg={12}>
          <Snackbar
            open={saveSurveySnackbarOpen}
            autoHideDuration={3000}
            onClose={(event, reason) => {
              setSaveSurveySnackbarOpen(false);
            }}
            message="Data successfully saved"
            className={'save-snackbar'}
            severity="success"
            variant="filled"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
            <ErrorAlert errorText={errorText} errorTitle={errorTitle} />
            {
              activeStep === steps.length ? (
                <div className='left-panel' style={{ width: 'auto' }}>
                  <React.Fragment>
                    <div className='no-survey'>
                      <Loading />
                      <h2 sx={{ mt: 2, mb: 1 }}>
                        Please wait...
                        <span>simulating your survey</span>
                      </h2>
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {/* <Button onClick={handleReset}>Reset</Button> */}
                    </Box>
                  </React.Fragment>
                </div>
              )
                :
                (
                  <React.Fragment>
                    {((window.location.pathname === "/" || window.location.pathname.includes('-survey')) && activeStep !== steps.length) && (
                        <Grid container className="stepper">
                          <Box>
                            <Stepper activeStep={activeStep}>
                              <Step key="Step 1">
                                <StepLabel>Audience</StepLabel>
                              </Step>
                              <Step key="Step 2">
                                <StepLabel>Questions</StepLabel>
                              </Step>
                              <Step key="Step 3">
                                <StepLabel>Review</StepLabel>
                              </Step>
                            </Stepper>
                          </Box>
                        </Grid>
                      )}
                    <h1>{currentContext === 'create' ? 'build a' : currentContext} Survey</h1>
                    <div className='step' style={{ display: activeStep === 0 ? 'block' : 'none' }}>
                      <Audiences
                        activeStep={activeStep}
                        audienceData={audienceData}
                        setAudienceData={setAudienceData}
                        hasAudienceData={hasAudienceData}
                        hasSurveyNameError={hasSurveyNameError}
                        surveyName={surveyName}
                        isShowAudienceError={isShowAudienceError}
                        isShowAudienceSizeError={isShowAudienceSizeError}
                        validateSurveyName={validateSurveyName}
                        validateAudience={validateAudience}
                        validateAudienceSize={validateAudienceSize}
                        segmentData={segmentData}
                        setSegmentData={setSegmentData}
                        pageHasError={pageHasError}
                        setPageHasError={setPageHasError}
                        segmentSizeLimit={segmentSizeLimit}
                        hasCustomSplit={hasCustomSplit}
                        setHasCustomSplit={setHasCustomSplit}
                        segmentDeleteCallback={segmentDeleteCallback}
                        currentContext={currentContext}
                        segmentsWithErrors={segmentsWithErrors}
                        segmentCharLimit={segmentCharLimit}
                        segmentCountLimit={segmentCountLimit}
                        segmentSuggestions={segmentSuggestions}
                        audienceSuggestions={audienceSuggestions}
                      />
                    </div>
                    <div className="step" style={{ display: activeStep === 1 ? 'block' : 'none' }}>
                      <Questions
                        activeStep={activeStep}
                        questionsData={questionsData}
                        setQuestionsData={setQuestionsData}
                        hasQuestionsData={hasQuestionsData}
                        handleUpload={handleUpload}
                        questionDeleteCallback={questionDeleteCallback}
                        questionsWithErrors={questionsWithErrors}
                        questionCharLimit={questionCharLimit}
                        questionCountLimit={questionCountLimit}
                        optionCharLimit={optionCharLimit}
                        optionCountLimit={optionCountLimit}
                      />
                    </div>

                    <div className="step" style={{ display: activeStep === 2 ? 'block' : 'none' }}>
                      <Details
                        surveyName={surveyName}
                        audienceData={audienceData}
                        segmentData={segmentData}
                        questionsData={questionsData}
                        hasSegmentData={hasSegmentData}
                        hasCustomSplit={hasCustomSplit}
                        selectSteps={selectSteps} />
                    </div>
                  </React.Fragment>
                )
            }
          </Box>
          <Box className="right-panel" style={{ display: activeStep !== 2 ? 'flex' : 'none'}}>
            {activeStep === 0 && (
              // Audiences page
              <Box className="info-box">
                <div className="info-box-icon">
                  {/* <CustomTooltip title={dynamicTooltipContent}> */}
                    <div
                      id="section-1-content"// this has to match an element in the FAQ page and determines the content of the tooltip
                      className="icon-wrapper"
                      // onMouseEnter={(e) => getDynamicTooltipContent(e.target)}>
                      >
                      <img src={logoWhiteSmall} alt="Info" />
                    </div>
                  {/* </CustomTooltip> */}
                </div>
                <div className="info-box-content">
                  <h3>Need help getting started?</h3>
                  <p>Check out our <span class="fake-link" onClick={() => setIsVideoModalOpen(true)}>walkthrough video</span> or take a look at some of&nbsp;
                  <a href="https://crowdwave-ai.github.io/faq/#crowdwave-uses" target="_blank">Crowdwave's suggested uses</a></p>
                </div>
              </Box>
            )}
            { activeStep === 1 && (
              // Questions page
              <Box className="info-box">
              <div className="info-box-icon">
                <div
                  id="section-1-content"// this has to match an element in the FAQ page and determines the content of the tooltip
                  className="icon-wrapper">
                  <img src={logoWhiteSmall} alt="Info" />
                </div>
              </div>
              <div className="info-box-content">
                <h3>Great answers start with great questions</h3>
                <p>Just like real people, Crowdwave respondents may be confused by ambiguous language or run-on sentences.</p>
                <p>
                  Check out some of our articles on <a href="https://crowdwave-ai.github.io/faq/#crowdwave-section" target="_blank">how to write great questions</a>
                  &nbsp; to ensure you use each type to its full potential.
                </p>
                <p>
                  Even great questions can sometimes return an unexpected answers, but don't worry! We can help you&nbsp;
                  <a href="https://crowdwave-ai.github.io/faq/#unexpected-results" target="_blank">refine your survey</a> to ensure
                  the most relevant results possible.
                </p>
              </div>
            </Box>
            )}
          </Box>
        </div>
        <Box className="btnblock" sx={{ display: 'flex', alignSelf: "flex-end", flexDirection: 'row', pt: 2, width: (activeStep === steps.length - 1) ? '95%' : '66.6%' }}>
          <Box sx={{ flex: '2'}}></Box>
          <Button variant="outlined"
            data-test-id="survey-back-btn"
            size={"large"}
            disabled={ activeStep === 0 || isSaving || pageHasError }
            onClick={handleBack}
            sx={{ mr: 1 }}
            startIcon={<ArrowBackIosRoundedIcon />}
          >
            Back
          </Button>
          {activeStep < 2 && (
            <Button
              data-test-id="survey-save-btn"
              className="save-btn"
              variant="outlined"
              size={"large"}
              color="success"
              sx={{ mr: 1 }}
              disabled={isSaving || pageHasError}
              onClick={() => saveSurvey(activeStep)}>
              Save
            </Button>
          )}
          <Button
            disabled={isSaving || pageHasError}
            data-test-id="survey-save-run-btn"
            variant="contained"
            size={"large"}
            onClick={() => handleCreateNext()}
            endIcon={<ArrowForwardIosRoundedIcon />}>
            {activeStep === steps.length - 1 ? 'Run Survey' : 'Save and Continue'}
          </Button>
        </Box>
        { pageHasError &&
          <Box className="survey-save-help-text">
            <Box>Please correct any errors or empty values before attempting to save or navigate away.</Box>
          </Box>
        }
    </div>
  );
}

export default withAuthenticationRequired(SurveyManager, {
  onRedirecting: () => <Loading />,
});