import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from './components/ui/Loading';
import Header from './components/ui/Header';
import Footer from './components/ui/Footer';
import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from './AuthProvider';
import { LicenseInfo } from '@mui/x-license';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import SurveyManager from './components/survey_creator/SurveyManager';
import About from './components/static/About';
import Result from './components/survey_creator/Result';
import TnCModal from './components/modals/TnCModal';
import TnC from './components/static/TnC';
import SurveyList from './components/list_views/SurveyList';
import VideoModal from './components/modals/VideoModal';
import Admin from './components/admin/Admin';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_PRO_KEY);

function App() {
  const [me, setMe] = useState(null);
  const { isAuthenticated, loginWithRedirect, error, isLoading, user } = useAuth0();
  const navigate = useNavigate();
  const api = useApi();
  const [isWhatsNewModalOpen, setIsWhatsNewModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  // limits
  const [audienceCharLimit, setAudienceCharLimit] = useState(0);
  const [audienceCountLimit, setAudienceCountLimit] = useState(0);
  const [segmentCharLimit, setSegmentCharLimit] = useState(0);
  const [segmentCountLimit, setSegmentCountLimit] = useState(0);
  const [questionCharLimit, setQuestionCharLimit] = useState(0);
  const [questionCountLimit, setQuestionCountLimit] = useState(0);
  const [optionCharLimit, setOptionCharLimit] = useState(0);
  const [optionCountLimit, setOptionCountLimit] = useState(0);

  const SurveyWrapper = ({ context }) => {
    const { existingSurveyId } = useParams();
  if (me !== null && me !== undefined) {

  }
    return <SurveyManager
      existingSurveyId={existingSurveyId ? existingSurveyId : null}
      currentContext={context}
      me={me}
      isVideoModalOpen={isVideoModalOpen}
      setIsVideoModalOpen={setIsVideoModalOpen}
      audienceCharLimit={audienceCharLimit}
      audienceCountLimit={audienceCountLimit}
      segmentCharLimit={segmentCharLimit}
      segmentCountLimit={segmentCountLimit}
      questionCharLimit={questionCharLimit}
      questionCountLimit={questionCountLimit}
      optionCharLimit={optionCharLimit}
      optionCountLimit={optionCountLimit}/>;
  };
  

  async function getMe() {
    // Get the user's profile and redirect to login if not authenticated
    try {
      const meResponse = await api.get(`/users/me`);

      setMe(meResponse.data);
      const me = meResponse.data;
      setAudienceCharLimit(me.max_chars_per_audience);
      setAudienceCountLimit(me.max_audiences_per_survey);
      setSegmentCharLimit(me.max_chars_per_segment);
      setSegmentCountLimit(me.max_segments_per_survey);
      setQuestionCharLimit(me.max_chars_per_question);
      setQuestionCountLimit(me.max_questions_per_survey);
      setOptionCharLimit(me.max_chars_per_option);
      setOptionCountLimit(me.max_options_per_question);

      if ((window.location.pathname !== "/accept-tnc" && window.location.pathname !== "/terms-and-conditions") && (meResponse.data.accepted_tos === undefined || meResponse.data.accepted_tos === false)) {
        navigate('/accept-tnc');
      }
    } catch (error) {
      console.error('Error fetching user', error);
      return Promise.reject(error);
    }
  }

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isLoading) {
    return <div>
            <Loading />
          </div>
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <Loading />
  }

  if (me === null) {
    getMe();
  }

  return (
    <div className="App">
      <Header
      sx={{ display: window.location.pathname.includes('accept-tnc') ? 'none' : 'block' }}
      isSurveyShown={window.location.pathname.includes("-survey")}
      isVideoModalOpen={isVideoModalOpen}
      setIsVideoModalOpen={setIsVideoModalOpen}
      isWhatsNewModalOpen={isWhatsNewModalOpen}
      setIsWhatsNewModalOpen={setIsWhatsNewModalOpen}
      user={me}
      setUser={setMe}/>
      <Routes>
        <Route path="/" element={<SurveyList />} />
        <Route path="/create-survey/" element={<SurveyWrapper context={'create'}/>} />
        <Route path="/copy-survey/:existingSurveyId" element={<SurveyWrapper context={'copy'}/>} />
        <Route path="/edit-survey/:existingSurveyId" element={<SurveyWrapper context={'edit'}/>} />
        <Route path="/about" element={<About />} />
        <Route path="/result/:surveyRunId" element={<Result />} />
        {/* <Route path="/terms-and-conditions" element={<TnC />} /> */}
        <Route path="/admin" element={<Admin user={me} />} />
        <Route path="/accept-tnc" element={<TnCModal />} />
        <Route path="/surveys" element={<SurveyList />} />
        <Route path="/welcome" element={<VideoModal isVideoModalOpen={isVideoModalOpen} setIsVideoModalOpen={setIsVideoModalOpen} />} />
        {/* <Route path="/audiences" element={<AudienceList />} />
        <Route path="/segments" element={<SegmentList />} />
        <Route path="/questions" element={<QuestionList />} /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
