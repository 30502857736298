import * as React from 'react';
import { Box } from '@mui/material';
import { useApi } from '../../AuthProvider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DndContext, useDroppable } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { RadioGroup, FormControl, FormControlLabel, Radio, FormGroup, Checkbox, InputLabel } from '@mui/material';
import DynamicChipList from './DynamicChipList';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CustomTooltip from '../ui/CustomTooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Link } from 'react-router-dom';

// depending on the type of item (label), render different edit and view layouts
const SegmentEditLayout = ({
    item,
    items,
    setListData,
    label,
    hasItemError,
    setHasItemError,
    hasListError,
    setHasListError,
    segmentSizeLimit,
    persistedError,
    setPersistedError,
    segmentSplit,
    audienceSize,
    hasCustomSplit,
    segmentCharLimit,
    segmentSuggestions
}) => {
    // find the index of the item in items
    const index = items.indexOf(item);
    const [nameEmpty, setNameEmpty] = React.useState(false);
    const [sizeZero, setSizeZero] = React.useState(false);
    const [sizeExceedsLimit, setSizeExceedsLimit] = React.useState(false);
    React.useEffect(() => {
        if (sizeExceedsLimit || sizeZero || nameEmpty) {
            setHasItemError(true);
        } else {
            setHasItemError(false);
        }
    }, [sizeExceedsLimit, sizeZero, nameEmpty, hasItemError]);
    return (
        <Box className="segment-edit">
            <Box className="dynamic-card-field">
                {hasCustomSplit && (
                    <TextField
                    id={`segment-size-input-${index+1}`}
                    className={`segment-size-input ${(sizeExceedsLimit || sizeZero) ? 'has-error' : ''}`}
                    label="Subgroup Size"
                    data-test-id={`segment-size-input-${index+1}`}
                    tabIndex={`${(index+1) * 2}`}
                    type="number"
                    error={sizeExceedsLimit || sizeZero}
                    helperText={(sizeExceedsLimit || sizeZero) ? 'Value must be between 1 and 1000' : ''}
                    max={10}
                    autoFocus={true}
                    disabled={!hasCustomSplit ? 'disabled' : ''}
                    value={item.segment_size}
                    sx={{ width: '175px' }}
                    onFocus={(e) => {
                        let segmentSize = e.target.value;
                        segmentSize === 0 ? segmentSize = '' : segmentSize = segmentSize;
                    }}
                    onChange={(e) => {
                        let segmentSize = e.target.value;
                        // restrict values from min/max and prevent empties
                        segmentSize < 0 ? segmentSize = 0 : segmentSize = segmentSize;
                        segmentSize > 1000 ? segmentSize = 1000 : segmentSize = segmentSize;
                        // set appropriate error states to bubble up from card to list
                        if (hasCustomSplit) {
                            if (parseInt(segmentSize) > segmentSizeLimit) {
                                setSizeExceedsLimit(true);
                                setSizeZero(false)
                            } else if (parseInt(segmentSize) < 1 || segmentSize === '') {
                                setSizeZero(true);
                            } else {
                                setSizeExceedsLimit(false);
                                setSizeZero(false);
                            }
                        } else {
                            segmentSize = segmentSplit;
                        }
                        // find the corresponding item in items and update the value
                        items.map((listItem) => {
                            if (listItem.id === item.id) {
                                item.segment_size = segmentSize;
                                e.target.value = segmentSize;
                            }
                        });
                        setListData([...items]);
                    }}/>
                )}
                {!hasCustomSplit && (
                    <p>{ segmentSplit } / { audienceSize !== null ? audienceSize : '0' } Respondents</p>
                )}
            </Box>
            <Box className="dynamic-card-field">
                <DynamicChipList
                    listData={item.segment_chips}
                    hasCustomSplit={hasCustomSplit}
                    setListData={(newChips) => {
                        if (newChips.length === 0) {
                            setHasItemError(true);
                            setHasListError(true);
                        } else {
                            setHasItemError(false);
                            setHasListError(false);
                            items.map((listItem) => {
                                if (listItem.id === item.id) {
                                    item.segment_chips = newChips;
                                }
                            });
                            setListData([...items]);// this sets the card list data
                        }
                    }}
                    segmentCharLimit={segmentCharLimit}
                    suggestions={segmentSuggestions}
                    parentLabel={'subgroup'}
                    label={'subgroup trait'}
                />
            </Box>
        </Box>
    );
};

const SegmentViewLayout = ({
    item,
    items,
    audienceSize,
    hasCustomSplit,
    segmentSplit
}) => {
    return (
        <Box className="segment-view" key={item.id}>
            <span className="segment-name">
                {item.segment_chips.map((chip, index) => {
                    return <Chip key={index} label={chip} className="segment-chip"/>;
                })}
            </span>
            <span className="segment-size">
                {!hasCustomSplit && (
                    <strong>{ segmentSplit } / { audienceSize !== null ? audienceSize : '0' } Respondents</strong>
                )}
                {hasCustomSplit && (
                    <strong>{ item.segment_size } Respondents</strong>
                )}
            </span>
        </Box>
    );
};

const QuestionEditLayout = ({
    item,
    items,
    setListData,
    hasItemError,
    setHasItemError,
    hasListError,
    setHasListError,
    questionCharLimit,
    questionCountLimit,
    optionCharLimit,
    optionCountLimit,
    label
}) => {
    const index = items.indexOf(item);
    const [textEmpty, setTextEmpty] = React.useState(false);
    const [optionsInvalid, setOptionsInvalid] = React.useState(false);
    const [numericScale, setNumericScale] = React.useState('["1","5"]');
    const [exampleVisible, setExampleVisible] = React.useState(false);
    const questionInfoTexts = [
        {
            type: 'short response',
            info: 'Short Responses are for open-ended questions and return up to a small paragraph of text',
            example: '"How do you prepare a monthly food budget while also considering other expenses?"'
        },
        {
            type: 'single choice',
            info: 'Single Choice questions allow respondents to select one option from a list',
            example: 'Question: "What is your favorite color?" Options: Red, Blue, Green, Other'
        },
        {
            type: 'multiple choice',
            info: 'Multiple Choice questions allow respondents to select multiple options from a list',
            example: 'Question: "What types of pet(s) do you own?" Options: Dog, Cat, Fish, Bird, Other, No Pets'
        },
        {
            type: 'numeric rating',
            info: 'Numeric Rating questions ask respondents to rate something on a scale (selected from the dropdown)',
            example: '"How important is fiber content when purchasing a breakfast cereal?"'
        },
    ];
    const [questionInfoText, setQuestionInfoText] = React.useState(questionInfoTexts[0].info);
    const [questionExampleText, setQuestionExampleText] = React.useState(questionInfoTexts[0].example);
    const getQuestionInfo = (questionType) => {
        questionInfoTexts.map((info) => {
            if (info.type === questionType.toLowerCase()) {
                setQuestionInfoText(info.info);
                setQuestionExampleText(info.example);
            }
        });
    }


    const questionHelperText = (questionText) => {
        let helperText = '';
        if (questionText === undefined || questionText.trim() === '') {
            helperText = 'Please enter a question';
        } else if (questionText.length > questionCharLimit) {
            helperText = `Question exceeds character limit of ${questionCharLimit}`;
        }
        return helperText;
    }
    React.useEffect(() => {
        getQuestionInfo(item.question_type);
    }, [item.question_type]);
    return (
        <Box className="question-edit">
            <Box className="dynamic-card-row">
            <Box className="dynamic-card-field" sx={{flexGrow: 2}}>
            <TextField
                id={`question-text-input-${index+1}`}
                className={`question-text-input ${hasItemError ? 'has-error' : ''}`}
                label="Question Text"
                data-test-id={`question-text-input-${index+1}`}
                tabIndex={`${(index+1) * 2}`}
                error={hasItemError}
                fullWidth
                multiline
                autoFocus={true}
                rows={4}
                value={item.question_text}
                helperText={hasItemError ? questionHelperText(item.question_text) : ''}
                sx={{ flexGrow: 2 }}
                onChange={(e) => {
                    // find the corresponding item in items and update the value
                    items.map((listItem) => {
                        if (listItem.id === item.id) {
                            item.question_text = e.target.value;
                        }
                    });
                    if (e.target.value.trim() === '') {
                        setTextEmpty(true);
                        setHasItemError(true);
                    } else if (e.target.value.length > questionCharLimit) {
                        setHasItemError(true);
                     } else {
                        setTextEmpty(false);
                        setHasItemError(false);
                    }
                    setListData([...items]);
                }}/>
            </Box>
            <Box className="question-type-wrapper">
                <Box
                sx={{ width: '200px' }}
                className="dynamic-card-field">
                    <FormControl>
                    <InputLabel shrink htmlFor="select-multiple-native">
                    Question Type
                    </InputLabel>
                    <Select
                    className="question-type-input"
                    value={item.question_type === '' ? 'short response' : item.question_type.toLowerCase()}
                    onChange={(e) => {
                        // find the corresponding item in items and update the value
                        items.map((listItem) => {
                            if (listItem.id === item.id) {
                                item.question_type = e.target.value;
                            }
                        });
                        setListData([...items]);
                        // clear out options for questions that dont need them
                        if (item.question_type.toLowerCase() !== 'numeric rating') {
                            item.raw_options = '';
                            item.options = [];
                        } else {
                            // when switching to numeric, autopopulate the options with default scale
                            item.options = JSON.parse(numericScale);
                        }
                    }}>
                        <MenuItem value="short response">Short Response</MenuItem>
                        <MenuItem value="single choice">Single Choice</MenuItem>
                        <MenuItem value="multiple choice">Multiple Choice</MenuItem>
                        <MenuItem value="numeric rating">Numeric Rating</MenuItem>
                        </Select>
                        </FormControl>
                    </Box>
                    {item.question_type.toLowerCase() === 'numeric rating' &&
                <>
                    <Box className="dynamic-card-field" sx={{width: '200px'}}>
                    <FormControl>
                        <InputLabel shrink htmlFor="select-multiple-native">
                        Response Scale
                        </InputLabel>
                        <Select
                        className="numeric-scale-input"
                        value={item.options === '' ? '["1","5"]' : JSON.stringify(item.options)}
                        onChange={(e) => {
                            // find the corresponding item in items and update the value
                            setNumericScale(e.target.value);
                            items.map((listItem) => {
                                if (listItem.id === item.id) {
                                    // the value is a string containing the array
                                    item.options = JSON.parse(e.target.value);
                                }
                            });
                            setListData([...items]);
                        }}>
                            <MenuItem value='["1","5"]'>1 - 5</MenuItem>
                            <MenuItem value='["1","7"]'>1 - 7</MenuItem>
                            <MenuItem value='["1","10"]'>1 - 10</MenuItem>
                            <MenuItem value='["0","10"]'>0 - 10 (NPS)</MenuItem>
                        </Select>
                    </FormControl>
                    </Box>
                </>
                }
                </Box>
            </Box>
            <Box className="dynamic-card-row">
                <Box className="dynamic-card-field" sx={{width: '100%'}}>
                    <span className="question-info-text">
                        <HelpIcon />
                        {questionInfoText}
                        <Button
                        onClick={() => setExampleVisible(!exampleVisible)}
                        className="question-example-reveal">
                                ({ exampleVisible ? 'Hide' : 'Show'} Example)
                            </Button>
                        {exampleVisible && (
                            <span className="question-info-example">
                                {questionExampleText}
                            </span>
                        )}
                    </span>
                </Box>
            </Box>
            {(item.question_type.toLowerCase() === 'single choice' || item.question_type.toLowerCase() === 'multiple choice') &&
            <Box className="dynamic-card-row">
                <Box className="dynamic-card-field" xs={12} sx={{width: '100%'}}>
                    <DynamicChipList
                        listData={item.options}
                        setListData={(newOptions) => {
                            items.map((listItem) => {
                                if (listItem.id === item.id) {
                                    item.options = newOptions;
                                }
                            });
                            setListData([...items]);
                        }}
                        optionCharLimit={optionCharLimit}
                        optionCountLimit={optionCountLimit}
                        parentLabel={'question'}
                        label={'choice'}
                    />
                </Box>
            </Box>
            }
            </Box>
    );
};

const QuestionViewLayout = ({
    item
}) => {
    return (
        <Box className="question-view" key={item.id}>
            <span className="question-type">
                {item.question_type !== 'numeric rating' && <strong>{item.question_type}</strong>}
                {item.question_type === 'numeric rating' && <strong>{item.question_type} ({item.options[0]} - {item.options[1]})</strong>}
            </span>
            <span className="question-text">{item.question_text}</span>
            { (item.question_type.toLowerCase() === 'short response' || item.question_type.toLowerCase() === 'long response') &&
                <span className="text-response">
                <TextField
                    disabled
                    fullWidth
                    sx={{ color: 'red'}}
                    value={`${item.question_type.charAt(0).toUpperCase() + item.question_type.slice(1)} text`}/>
                </span>
            }
            {item.question_type.toLowerCase() === 'single choice' &&
                <FormControl>
                <RadioGroup
                    className='radio-group'
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={item.options[0]}
                    name="radio-buttons-group"
                    row
                >
                    {item.options.map((option, index) => (
                        <FormControlLabel disabled value={option} control={<Radio />} label={option} key={index}/>
                    ))}
                </RadioGroup>
                </FormControl>
            }
            {item.question_type.toLowerCase() === 'multiple choice' &&
                <FormGroup className='checkbox-array'>
                {item.options.map((option, index) => (
                    <>
                        {index <= 1 &&
                            <FormControlLabel control={<Checkbox defaultChecked disabled />} label={option} />
                        }
                        {index > 1 &&
                            <FormControlLabel control={<Checkbox disabled />} label={option} />
                        }
                    </>
                ))}
                </FormGroup>
            }
        </Box>
    );
};

const Draggable = ({
    label,
    formattedLabel,
    item,
    items,
    setListData,
    id,
    index,
    isEditingCard,
    closeAllExcept,
    itemDeleteCallback,
    segmentSizeLimit,
    hasItemError,
    setHasItemError,
    hasListError,
    setHasListError,
    itemsWithErrors,
    isItemEmpty,
    allowEmpty,
    audienceSize,
    hasCustomSplit,
    segmentLimitReached,
    segmentCharLimit,
    segmentSuggestions,
    questionLimitReached,
    questionCharLimit,
    questionCountLimit,
    optionCharLimit,
    optionCountLimit,
    isSortable }) => {

    let singleItem = items && items.length === 1;
    const api = useApi();
    // console.log('items:', items);
    const [isEditing, setIsEditing] = React.useState(isEditingCard);
    const [persistedError, setPersistedError] = React.useState(false);
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable(item);
    // console.log('inside Draggable', label, item, id, index, isSortable);
    const itemStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
        // set all other cards to not editing
        closeAllExcept(items.indexOf(item));
    }

    const handleDelete = async (id) => {
        const newItems = items.filter((item) => item.id !== id);
        // setListData(newItems);

        // state data is updated but we need to update the server data
        if (label === 'subgroup') {
            if (newItems.length === 0) {
                setHasListError(false);
                setHasItemError(false);
            }
            const deleteResponse = await itemDeleteCallback(item.audience_segment_id);
            if (deleteResponse) {
                setListData(newItems);
            }
        } else if (label === 'question') {
            const deleteResponse = await itemDeleteCallback(item.question_id);
            if (deleteResponse || item.question_id === '') {
                setListData(newItems);
            }
        }
    }

    const handleCopyItem = () => {
        // copy the item and add it directly after the original in the list
        const newItem = { ...item, id: Math.floor(Math.random() * 10000), sort_order: items.sort_order + 1 };
        if (label === 'subgroup') {
            newItem.audience_segment_id = "";
            newItem.created_at = "";
            newItem.segment_chips = item.segment_chips?.length > 0 ? item.segment_chips : [];
            !hasCustomSplit ? newItem.segment_size = Math.floor(audienceSize / (items.length+1)) : newItem.segment_size = item.segment_size;
        } else if (label === 'question') {
            newItem.question_id = "";
            newItem.created_at = "";
        }
        // if !hascustomSplit, we need to update the segment size of all items
        if (!hasCustomSplit) {
            items.map((listItem) => {
                listItem.segment_size = Math.floor(audienceSize / (items.length+1));
            });
        }
        // since we want it to show up directly after the original we need to look at the original and figure
        // out how many exist AFTER it in the list
        const originalIndex = items.indexOf(item);
        const itemsAfterOriginal = items.slice(originalIndex+1);
        const itemsAfterOriginalCount = itemsAfterOriginal.length;
        newItem.sort_order = item.sort_order + 1;
        if (itemsAfterOriginalCount > 0) {
            // the original item was not the last one in the list, so we need to increase the sort_order of
            // each item after the original by 1 so we can slip the copy in between the original and its next sibling
            items.map((listItem) => {
                if (listItem.sort_order > item.sort_order) {
                    listItem.sort_order += 1;
                }
            });
        }
        // re-sort list with new item in it
        items.splice(originalIndex+1, 0, newItem);
        setListData([...items]);

    }

    React.useEffect(() => {
        // toggles editing states of other cards
        setIsEditing(isEditingCard);
    }, [isEditingCard]);

    React.useEffect(() => {
    }, [hasItemError]);

    const [segmentSplit, setSegmentSplit] = React.useState(Math.floor(audienceSize / items.length));
    React.useEffect(() => {
        // detect if the items that changes were segments
        const isSegment = items[0].hasOwnProperty('segment_size');
        // if we're not using custom split, we need to update all segment sizes
        // any time audience size changes, a segment is added, or custom split is toggled
        if (!hasCustomSplit && isSegment) {
            setSegmentSplit(Math.floor(audienceSize / items.length));
            items.map((listItem) => {
                listItem.segment_size = Math.floor(audienceSize / items.length);
            });
        }
    }, [audienceSize, items.length, hasCustomSplit]);
    return (
        <>
            <Box className={`dynamic-card ${isEditing ? 'editing' : ''} ${(itemsWithErrors.includes(item) && !isItemEmpty(item)) ? 'has-error' : ''}`}
                key={index}
                ref={setNodeRef}
                style={itemStyle}>
                {isSortable && (
                    <Box className="dynamic-card-handle">
                        <span {...attributes} {...listeners} className="dynamic-card-handle-target">
                            <DragIndicatorIcon />
                        </span>
                    </Box>
                )}
                <Box className="dynamic-card-header">
                    <span className="dynamic-card-title">
                        {formattedLabel} {index + 1} {index === 0 && !allowEmpty && ' (Required)'}</span>
                    <span className="dynamic-card-actions">
                        {!isEditing &&
                            <CustomTooltip title={"Edit"}>
                                <IconButton
                                    className="dynamic-card-action edit"
                                    alt={"Edit"}
                                    color={'primary'}
                                    onClick={() => {
                                        handleEditToggle();
                                    }}>
                                    <EditIcon />
                                </IconButton>
                            </CustomTooltip>
                        }
                        {((label === 'subgroup' && !segmentLimitReached && !itemsWithErrors.includes(item)) || ((label === 'question' && !questionLimitReached && !itemsWithErrors.includes(item)))) &&
                            <CustomTooltip title={"Copy"}>
                                <IconButton
                                    className="dynamic-card-action copy"
                                    alt={"Copy"}
                                    color={'primary'}
                                    onClick={() => {
                                        handleCopyItem();
                                        process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', `${label} copied`);
                                    }}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </CustomTooltip>
                        }
                        {(allowEmpty || items.indexOf(item)) !== 0 &&
                            <CustomTooltip title={"Delete"}>
                                <IconButton
                                    className="dynamic-card-action delete"
                                    alt={"Delete"}
                                    onClick={() => {
                                        handleDelete(item.id);
                                        process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', `${label} deleted`);
                                    }}>
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                            </CustomTooltip>
                        }
                    </span>
                </Box>
                <Box className="dynamic-card-body">
                    {!isEditing && label === 'subgroup' && <SegmentViewLayout
                        audienceSize={audienceSize}
                        hasCustomSplit={hasCustomSplit}
                        segmentSplit={segmentSplit}
                        items={items}
                        item={item}/>
                    }
                    {!isEditing && label === 'question' && <QuestionViewLayout item={item}/>
                    }
                    {isEditing && label === 'subgroup' && <SegmentEditLayout
                        item={item}
                        items={items}
                        setListData={setListData}
                        segmentSplit={segmentSplit}
                        segmentSizeLimit={segmentSizeLimit}
                        segmentCharLimit={segmentCharLimit}
                        hasItemError={hasItemError}
                        setHasItemError={setHasItemError}
                        hasListError={hasListError}
                        setHasListError={setHasListError}
                        persistedError={persistedError}
                        setPersistedError={setPersistedError}
                        audienceSize={audienceSize}
                        hasCustomSplit={hasCustomSplit}
                        segmentSuggestions={segmentSuggestions}
                        label={label}/>
                    }
                    {isEditing && label === 'question' && <QuestionEditLayout
                        item={item}
                        items={items}
                        setListData={setListData}
                        hasItemError={hasItemError}
                        setHasItemError={setHasItemError}
                        hasListError={hasListError}
                        setHasListError={setHasListError}
                        persistedError={persistedError}
                        setPersistedError={setPersistedError}
                        questionCharLimit={questionCharLimit}
                        questionCountLimit={questionCountLimit}
                        optionCharLimit={optionCharLimit}
                        optionCountLimit={optionCountLimit}
                        label={label}/>
                    }
                </Box>
            </Box>
        </>
    );
};

const Droppable = ({
    id,
    items,
    setListData,
    formattedLabel,
    isSortable,
    isEditingIndex,
    setIsEditingIndex,
    itemDeleteCallback,
    segmentSizeLimit,
    hasItemError,
    setHasItemError,
    hasListError,
    emptyItem,
    allowEmpty,
    setHasListError,
    itemsWithErrors,
    audienceSize,
    hasCustomSplit,
    segmentLimitReached,
    segmentCharLimit,
    segmentSuggestions,
    questionLimitReached,
    questionCharLimit,
    questionCountLimit,
    optionCharLimit,
    optionCountLimit,
    label }) => {
    // console.log('inside Droppable', id, items);
    const closeAllExcept = (index) => {
        setIsEditingIndex(index);
    }

    const isItemEmpty = (item) => {
        // this function denotes an empty item for segments + questions
        // so that we dont mark newly added items that haven't been edited as erroneous
        if (label === 'subgroup') {
            return item.segment_name === '' && item.segment_size === '';
        } else if (label === 'question') {
            return item.question_text === '' && item.question_type === '' && item.options.length === 0;
        }
    }

    return (
        <Box className={`dynamic-card-list ${itemsWithErrors.length > 0 && (!isItemEmpty(itemsWithErrors[0]) && !allowEmpty) ? 'has-error' : ''}`}>
            <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
                {/* for each list item, render a DynamicCard */}
                {items.map((item, index) => (
                    <Draggable
                        key={item.id}
                        id={item.id}
                        item={item}
                        items={items}
                        setListData={setListData}
                        index={index}
                        label={label}
                        isEditingCard={items.indexOf(item) === isEditingIndex}
                        closeAllExcept={closeAllExcept}
                        removable={items.length > 1}
                        formattedLabel={formattedLabel}
                        itemDeleteCallback={itemDeleteCallback}
                        segmentSizeLimit={segmentSizeLimit}
                        segmentCharLimit={segmentCharLimit}
                        questionCharLimit={questionCharLimit}
                        questionCountLimit={questionCountLimit}
                        optionCharLimit={optionCharLimit}
                        optionCountLimit={optionCountLimit}
                        hasItemError={hasItemError}
                        setHasItemError={setHasItemError}
                        hasListError={hasListError}
                        setHasListError={setHasListError}
                        itemsWithErrors={itemsWithErrors}
                        allowEmpty={allowEmpty}
                        isItemEmpty={isItemEmpty}
                        audienceSize={audienceSize}
                        hasCustomSplit={hasCustomSplit}
                        questionLimitReached={questionLimitReached}
                        segmentLimitReached={segmentLimitReached}
                        segmentSuggestions={segmentSuggestions}
                        isSortable={isSortable}/>

                ))}
            </SortableContext>
        </Box>
    );
};

const DynamicCardList = ({
    label = '',
    listData = [],
    setListData,
    emptyItem = {},
    itemDeleteCallback = () => {},
    segmentSizeLimit = 1000,
    segmentCharLimit,
    segmentCountLimit,
    questionCharLimit,
    questionCountLimit,
    optionCharLimit,
    optionCountLimit,
    itemsWithErrors = [],
    pageHasError = false,
    setPageHasError = () => {},
    allowEmpty = false,
    audienceSize = 0,
    hasCustomSplit = false,
    segmentSuggestions,
    isSortable = false }) => {

    const api = useApi();
    const listId = label.toLowerCase().replace(' ', '-').concat('-dynamic-card-list');
    const formattedLabel = label ? label.charAt(0).toUpperCase() + label.slice(1) : '';
    // prevent adding more segments than audience size allows, if split is not custom
    // e.g. you can't have audience size = 4 and split that amongst 5 segments
    const segmentLimitReached = audienceSize !== null && (!hasCustomSplit && listData.length >= audienceSize) || (listData.length >= segmentCountLimit);
    const questionLimitReached = listData.length >= questionCountLimit;
    const handleItemAdd = (e) => {
        if (label === 'subgroup' && segmentLimitReached) {
            return;
        } else if (label === 'subgroup' && !hasCustomSplit) {
            // if we're not using custom split, we need to update all segment sizes
            listData.map((listItem) => {
                listItem.segment_size = Math.floor(audienceSize / (listData.length+1));
            });
        } else if (label === 'question' && questionLimitReached) {
            return;
        }
        const newItem = { ...emptyItem, id: Math.floor(Math.random() * 10000), sort_order: listData.length + 1 };
        setListData([...listData, newItem]);
        setIsEditingIndex(listData.length);
    };
    const [isEditingIndex, setIsEditingIndex] = React.useState(0);
    const [hasItemError, setHasItemError] = React.useState(false);
    const [hasListError, setHasListError] = React.useState(false);

    const handleDragStart = (e) => {
        // console.log('drag start', e);
        // console.log('listData', listData);
        // setListData(listData);
    };

    const handleDragEnd = (e) => {
        if (!e.over) return;

        if (e.active.id !== e.over.id) {
            setListData((listData) => {
                // find the original index of the dragged item
                const oldIndex = listData.indexOf(listData.find(item => item.id.toString() === e.active.id.toString()));
                // find the index of the item that the dragged item is being dropped onto
                const newIndex = listData.indexOf(listData.find(item => item.id.toString() === e.over.id.toString()));
                const newArray = arrayMove(listData, oldIndex, newIndex);
                // newArray has correct index order but incorrect sort order, so sync them up
                newArray.map((item, index) => item.sort_order = index + 1);
                if (oldIndex === isEditingIndex) {
                    setIsEditingIndex(newIndex);
                }
                return newArray;
            });
        }
    }

    React.useEffect(() => {
        console.log('card list changed', listData);
        if (listData.length === 0 && !allowEmpty) {
            setListData([{...emptyItem, id: Math.floor(Math.random() * 10000)}]);
        }
        if (listData.length === 0 && allowEmpty) {
            setHasListError(false);
            setHasItemError(false);
            setPageHasError(false);
        }
    }, [listData]);

    return (
        <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}>
            <Droppable
                id={listId}
                items={listData}
                setListData={setListData}
                label={label}
                formattedLabel={formattedLabel}
                emptyItem={emptyItem}
                allowEmpty={allowEmpty}
                isEditingIndex={isEditingIndex}
                setIsEditingIndex={setIsEditingIndex}
                itemDeleteCallback={itemDeleteCallback}
                segmentSizeLimit={segmentSizeLimit}
                segmentCharLimit={segmentCharLimit}
                questionCharLimit={questionCharLimit}
                questionCountLimit={questionCountLimit}
                optionCharLimit={optionCharLimit}
                optionCountLimit={optionCountLimit}
                hasItemError={hasItemError}
                setHasItemError={setHasItemError}
                hasListError={hasListError}
                setHasListError={setHasListError}
                itemsWithErrors={itemsWithErrors}
                audienceSize={audienceSize}
                hasCustomSplit={hasCustomSplit}
                segmentLimitReached={segmentLimitReached}
                questionLimitReached={questionLimitReached}
                segmentSuggestions={segmentSuggestions}
                isSortable={isSortable}/>
            <Box className="dynamic-card-list-controls">
                <Button
                data-test-id={`add-${label}-btn`}
                onClick={handleItemAdd}
                variant="contained"
                startIcon={<AddIcon />}
                disabled={(label === 'subgroup' && segmentLimitReached) || (label === 'question' && questionLimitReached)}
                style={{ marginTop: '20px' }}
                >
                Add {listData.length === 0 ? 'a' : 'another'} {formattedLabel}
                </Button>
                <Box className="dynamic-card-add-helper-text">
                    {label === 'subgroup' && segmentLimitReached && (
                        <>
                        {listData.length + 1 > segmentCountLimit && `The number of audience subgroups cannot exceed ${segmentCountLimit}`}
                        {listData.length + 1 > audienceSize && `The number of audience subgroups cannot exceed the Audience Size (${audienceSize})`}
                            <br />If you wish to add more subgroups, you can enable Custom Distribution, increase your audience size, or consolidate your subgroups.
                        </>
                    )}
                    {label === 'question' && questionLimitReached && (
                        <>
                        {`The number of questions cannot exceed ${questionCountLimit}`}
                        </>
                    )}
                </Box>
            </Box>
        </DndContext>);
}

export default DynamicCardList;